import React, { FC, useMemo } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as DeckIcon } from '../../../assets/pictures/icons/deck.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  progress: {
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, .1)',
    '& > div': {
      backgroundColor: 'white',
    },
  },
  progressLabel: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.1em',
    textAlign: 'left',
  },
  desk: {
    '& > path': {
      fill: 'white'
    }
  }
});
type Props = {
  completed: number;
  total: number;
}
const QuizProgressComponent: FC<Props> = ({ completed, total }) => {
  const classes = useStyles();
  const progress = useMemo(
    () => (completed / total) * 100,
    [completed, total],
  );
  return (
    <Grid
      container
      spacing={2}
      alignItems={'center'}>
      <DeckIcon className={classes.desk}/>
      <Grid item xs>
        <LinearProgress
          color={'secondary'}
          variant="determinate"
          value={progress}
          className={classes.progress}
        />
      </Grid>
      <Grid item>
        <Typography variant={'body2'} className={classes.progressLabel}>
          {completed}/{total}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default QuizProgressComponent;

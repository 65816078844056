
let prevH = -1;

const resize = () => {
  if (prevH === window.innerHeight) {
    return;
  }
  prevH = window.innerHeight;

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh',  vh + "px");
  document.documentElement.style.setProperty('--vh100',  window.innerHeight + "px");
};


window.addEventListener("resize", resize);
window.addEventListener("orientationchange", resize);
setInterval(resize, 150); //just in case

export const vhfix = ()=> {
  resize();
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {vhfix} from './utils/vhfix';
import './utils/i18n';
import { sessionId } from './utils/session-id';

vhfix();

console.log(`CSWR v. ${process.env.REACT_APP_VERSION}`);
sessionStorage.setItem('SESSION_ID', sessionId());
ReactDOM.render(
  <React.StrictMode>
    <div className={process.env.REACT_APP_QUIZ_TYPE === 'green' ? 'green' : 'digital'}>
      <App/>
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export function requireAll(r: any) {
  return Object.fromEntries(
    r.keys().map(function (mpath: any, ...args: any) {
      const result = r(mpath, ...args);
      const name = mpath
        .replace(/(?:^[./]*\/|\.[^.]+$)/g, '') // Trim
        .replace(/\//g, '_') // Relace '/'s by '_'s
      ;
      return [name, result];
    }),
  );
}

const languages = requireAll((require as any).context(`../assets/i18n/${process.env.REACT_APP_QUIZ_TYPE}/`, true, /\.json$/));
let resources: any = {};
Object.keys(languages).forEach((k: string) => {
  try {
    const lang: string = k.split('_')[0];
    const section: string = k.split('_')[1];
    resources = {
      ...resources,
      [lang]: {
        ...resources[lang],
        [section]: languages[k]
        ,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      en: requireAll((require as any).context(`../assets/i18n/${process.env.REACT_APP_QUIZ_TYPE}/en`, true, /\.json$/)),
    };
  }
});
const lng: () => string = () => {
  try {
    // console.log('Browser lang:', navigator.language);
    const lang = navigator.languages[0].split('-');
    let selectedLang = window.location.pathname.replace(process.env.REACT_APP_QUIZ_TYPE + '/', '');
    selectedLang = selectedLang.split('/')[1];

    if (!selectedLang || selectedLang.length > 2) {
      // Should not pass from here when redirect will be handled by php
      window.history.pushState(null, '', `/${process.env.REACT_APP_QUIZ_TYPE}/${lang[0]}/`);
      // eventually, if php solution is too complicated, try this (Pay attention, check that no redirect loops are created)
      //window.location.href = `/${process.env.REACT_APP_QUIZ_TYPE}/${lang[0]}/`; //redirect = php page load correct strings
      return lang[0];
    }
    // EDIT THIS TO REFRESH INTO NEW PAGE
    //window.history.pushState(null, '', `/${process.env.REACT_APP_QUIZ_TYPE}//${selectedLang}/the-quiz`)
    window.history.pushState(null, '', `/${process.env.REACT_APP_QUIZ_TYPE}/${selectedLang}/`)
    return selectedLang;
  } catch (e) {
    console.error(e);
    window.history.pushState(null, '', `/${process.env.REACT_APP_QUIZ_TYPE}/en/`);
    return 'en';
  }
};
i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: lng(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    returnEmptyString: false,
    appendNamespaceToMissingKey: false,
  });

export default i18next;

import GTopPic from '../assets/pictures/green/home-landing.png';
import GResult1Pic from '../assets/pictures/green/result_1.png';
import GResult2Pic from '../assets/pictures/green/result_2.png';
import GResult3Pic from '../assets/pictures/green/result_3.png';
import DTopPic1 from '../assets/pictures/digital/home-landing-1.png';
import DTopPic2 from '../assets/pictures/digital/home-landing-2.png';
import DResult1Pic from '../assets/pictures/digital/result_1.png';
import DResult2Pic from '../assets/pictures/digital/result_2.png';
import DResult3Pic from '../assets/pictures/digital/result_3.png';

type PicturesType = {
  result_1: string;
  result_2: string;
  result_3: string;
  landing: string;
  landing_2?: string;
}
let pictures: PicturesType;
if (process.env.REACT_APP_QUIZ_TYPE === 'green') {
  pictures = {
    result_1: GResult1Pic,
    result_2: GResult2Pic,
    result_3: GResult3Pic,
    landing: GTopPic,
  };
} else {
  pictures = {
    result_1: DResult1Pic,
    result_2: DResult2Pic,
    result_3: DResult3Pic,
    landing: DTopPic2,
    landing_2: DTopPic1,
  };
}

export default pictures;

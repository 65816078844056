import HomeContainer from '../views/containers/home/home.container';
import TheQuizContainer from '../views/containers/the-quiz/the-quiz.container';
import TheResultContainer from '../views/containers/the-result/the-result.container';

export enum RoutesKeys {
  home = 'home',
  quiz = 'quiz',
  result = 'result',
}

export interface RouteType {
  key: RoutesKeys;
  path: string;
  component: any;
  exact?: boolean;
  children?: any[];
}

export const routes: RouteType[] = [
  {
    key: RoutesKeys.home,
    component: HomeContainer,
    path: '/:lang/',
    exact: true,
  },
  {
    key: RoutesKeys.quiz,
    component: TheQuizContainer,
    path: '/:lang/the-quiz',
    exact: true,
  },
  {
    key: RoutesKeys.result,
    component: TheResultContainer,
    path: '/:lang/the-result',
    exact: true,
  },
];

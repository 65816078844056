import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { dataFetchReducer } from '../utils/axsios.states';

const url = `${process.env.REACT_APP_ENDPOINT}/api/locale`;
const GetLocaleHook = () => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: undefined,
  });

  useEffect(() => {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await axios(url);
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      };
      fetchData();
      return () => {
        didCancel = true;
      };
    },
    [],
  );
  return [state];
};

export default GetLocaleHook;

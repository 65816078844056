import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { dataFetchReducer } from '../utils/axsios.states';
import { useTranslation } from 'react-i18next';

export type UserType = {
  age: string;
  gender: 'M' | 'F' | 'O' | '';
  country: string;
}
const url = `${process.env.REACT_APP_ENDPOINT}/api/form`;
const SaveUserDataHook = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<UserType>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: undefined,
  });

  useEffect(() => {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await axios(url, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            data:{
              id: sessionStorage.getItem('SESSION_ID'),
              quiz: process.env.REACT_APP_QUIZ_TYPE,
              language: i18n.language,
              age: data?.age,
              gender: data?.gender,
              country: data?.country,
            }
          });
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      };
      if (data) {
        fetchData();
      }
      return () => {
        didCancel = true;
      };
    },
    [data, i18n],
  );
  return [state, setData];
};

export default SaveUserDataHook;

import React, { FC } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as FacebookIcon } from '../../../assets/pictures/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/pictures/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/pictures/icons/linkedin.svg';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { trackOutboundLink } from '../../../utils/share';

const useStyles = makeStyles({
  icon: {
    width: '46px',
    height: '46px',
    backgroundColor: 'red',
    borderRadius: '100%',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span > svg': {
      width: 'auto',
      height: '22px',
      '& path': {
        fill: 'white!important',
      },
    },
  },
  facebook: {
    backgroundColor: '#3B5998',
    '&:hover': {
      backgroundColor: '#3B5998',
    },
    '&:active': {
      backgroundColor: '#3B5998',
    },
    '&:focues': {
      backgroundColor: '#3B5998',
    },
  },
  instagram: {
    backgroundColor: '#C13584',
    '&:hover': {
      backgroundColor: '#C13584',
    },
    '&:active': {
      backgroundColor: '#C13584',
    },
    '&:focues': {
      backgroundColor: '#C13584',
    },
  },
  twitter: {
    backgroundColor: '#1DA1F2',
    '&:hover': {
      backgroundColor: '#1DA1F2',
    },
    '&:active': {
      backgroundColor: '#1DA1F2',
    },
    '&:focues': {
      backgroundColor: '#1DA1F2',
    },
  },
  linkedin: {
    backgroundColor: '#0077B5',
    '&:hover': {
      backgroundColor: '#0077B5',
    },
    '&:active': {
      backgroundColor: '#0077B5',
    },
    '&:focues': {
      backgroundColor: '#0077B5',
    },
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
}
const ShareDialogComponent: FC<Props> = ({ onClose, open }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t } = useTranslation('result');
  const handleClose = () => {
    onClose();
  };

  const share = (social: 'twitter' | 'facebook' | 'linkedin') => {
    let prefix: string = '';
    let postfix: string = '';
    switch (social) {
      case 'facebook':
        prefix = 'https://www.facebook.com/sharer.php?u=';
        break;
      case 'linkedin':
        prefix = 'https://www.linkedin.com/shareArticle?mini=true&url=';
        postfix = `&title=${t('home:title')}summary=${t('home:subtitle')}&source=LinkedIn`;
        break;
      case 'twitter':
        prefix = 'https://twitter.com/share?url=';
        break;
      default:
        console.error('Social not supported');
    }
    window.open(`${prefix}${process.env.PUBLIC_URL}/${i18n.language}/${postfix}`, '_blank');
    trackOutboundLink(`${prefix}${process.env.PUBLIC_URL}/${i18n.language}`);
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth
      aria-labelledby="share-dialog-title"
      open={open}>
      <DialogTitle id="share-dialog-title">
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item>
            <Typography variant={'h6'} style={{ textTransform: 'capitalize' }}>
              {t('share')}
            </Typography>
          </Grid>
          {onClose ? (
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <Close/>
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </DialogTitle>
      <CardContent>
        <Box mb={3}>
          <Grid container justify={'center'} spacing={3}>
            <Grid item>
              <IconButton
                onClick={() => share('facebook')}
                className={[classes.icon, classes.facebook].join(' ')}>
                <FacebookIcon/>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => share('twitter')}
                className={[classes.icon, classes.twitter].join(' ')}>
                <TwitterIcon/>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => share('linkedin')}
                className={[classes.icon, classes.linkedin].join(' ')}>
                <LinkedinIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Dialog>
  );
};

export default ShareDialogComponent;

import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Container } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import StartQuizButtonComponent from '../start-quiz-button/start-quiz-button.component';
import CookiesComponent from '../cookies/cookies.component';
import SaveUserDataHook, { UserType } from '../../../hooks/save-user-data.hook';
import formData from '../../../utils/data';

type Props = {
  country: string
}
const UserDataComponent: FC<Props> = ({ country }) => {
  const [{ loading }, save] = SaveUserDataHook();
  const { t } = useTranslation('form');
  const [user, setUser] = useState<UserType>({
    gender: '',
    age: '',
    country,
  });
  const history = useHistory();
  const countries = useMemo(
    () => Object.keys(formData.countries),
    [],
  );
  useEffect(
    () => {
      setUser({
        ...user,
        country,
      })
    },
    [country],
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(({
      ...user,
      [e.target.name]: e.target.value,
    } as UserType));
  };
  const submit = useCallback(
    async () => {
      try {
        await save(user);
        history.push('the-quiz');
      } catch (e) {
        console.error(e);
      }
    },
    [user, history, save],
  );
  return (
    <Container maxWidth={'sm'}>
      <Grid
        container
        direction={'column'}
        spacing={2}>
        <Grid item>
          <FormControl
            fullWidth
            required
            aria-required={'true'}>
            <Typography
              variant={'body1'}
              gutterBottom>
              {t('age_label')}
            </Typography>
            <TextField
              variant={'outlined'}
              type={'number'}
              value={user?.age}
              onChange={handleChange}
              name={'age'}
              select>
              <MenuItem value={'<20'}>{t('age_option_0')}</MenuItem>
              <MenuItem value={'20-35'}>{t('age_option_1')}</MenuItem>
              <MenuItem value={'35-50'}>{t('age_option_2')}</MenuItem>
              <MenuItem value={'>50'}>{t('age_option_3')}</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            fullWidth
            required
            aria-required={'true'}>
            <Typography
              variant={'body1'}
              gutterBottom>
              {t('gender_label')}
            </Typography>
            <TextField
              variant={'outlined'}
              value={user?.gender}
              onChange={handleChange}
              name={'gender'}
              select>
              <MenuItem value={'M'}>{t('gender_option_0')}</MenuItem>
              <MenuItem value={'F'}>{t('gender_option_1')}</MenuItem>
              <MenuItem value={'O'}>{t('gender_option_2')}</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            fullWidth
            required
            aria-required={'true'}>
            <Typography
              variant={'body1'}
              gutterBottom>
              {t('location_label')}
            </Typography>
            <TextField
              variant={'outlined'}
              value={user?.country}
              onChange={handleChange}
              name={'country'}
              select>
              {countries.map(
                (key) =>
                  <MenuItem value={key} key={key}>{formData.countries[key]}</MenuItem>,
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item>
          <CookiesComponent/>
        </Grid>
        <Grid item>
          <Box my={2}>
            <StartQuizButtonComponent
              loading={loading}
              disabled={!user?.age || !user?.country || !user.gender}
              handleClick={submit}/>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserDataComponent;

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import UserDataComponent from '../../components/user-data/user-data.component';
import colors from '../../../themes/colors';
import StartQuizButtonComponent from '../../components/start-quiz-button/start-quiz-button.component';
import AppBarComponent from '../../components/app-bar/app-bar.component';
import LanguagesMenuComponent from '../../components/languages-menu/languages-menu.component';
import ThumbPic from '../../../assets/pictures/hand_thumb_up.png';
import Grid from '@material-ui/core/Grid';
import pictures from '../../../themes/pictures';
import GetLocaleHook from '../../../hooks/get-locale.hook';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import '../../../themes/styles/wcag.css';

const useStyles = makeStyles({
  wrapper: {
    overflowX: 'hidden',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    position: 'fixed',
    '& > div': {
      position: 'relative',
    },
  },
  topPic: {
    position: 'absolute',
    width: '300px',
    height: '300px',
    left: 0,
    top: '-200px',
    zIndex: -1,
    '-webkit-transition': 'all 1s ease-in-out',
    transition: 'all 1s ease-in-out',
    '-webkit-transform': 'translate(0, 0) rotate(5deg)',
    transform: 'translate(0, 0) rotate(5deg)',
  },
  animateTopPic: {
    '-webkit-transform': 'translate(40%, 27%) rotate(5deg)',
    transform: 'translate(40%, 27%) rotate(5deg)',
  },
  topPic2: {
    position: 'absolute',
    width: '300px',
    height: '300px',
    left: '-30%',
    top: '-200px',
    zIndex: -1,
    '-webkit-transition': 'all 1s ease-in-out',
    transition: 'all 1s ease-in-out',
    '-webkit-transform': 'translate(0, 0)',
    transform: 'translate(0, 0)',
  },
  animateTopPic2: {
    '-webkit-transform': 'translate(0px, 50%) rotate(10deg)',
    transform: 'translate(0px, 50%) rotate(10deg)',
  },
  title: {
    marginTop: '150px',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '48px',
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: '-0.01em',
    '@media (min-width: 768px)': {
      width: '55%',
      marginTop: '35%',
    },
  },
  alternativeCTAS: {
    padding: '32px 16px',
    zIndex: 999,
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  background: {
    backgroundColor: colors.palette_color_2,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  topCard: {
    width: '270px',
    height: '430px',
    backgroundColor: colors.palette_color_5,
    top: '150px',
    right: '-20px',
    position: 'absolute',
    transform: 'rotate(-5deg)',
    borderRadius: '12px',
    boxShadow: '-15px 25px 50px rgba(0,0,0,.3)',
    zIndex: -1,
    '@media (min-width: 768px)': {
      top: '-120px',
      right: '-15%',
    },
  },
  textCard: {
    width: '270px',
    height: '500px',
    backgroundColor: colors.palette_color_4,
    padding: '2rem 1.5rem',
    marginTop: '40px',
    left: '0',
    borderRadius: '12px',
    '@media (min-width: 768px)': {
      top: '170px',
      left: '60%',
      position: 'absolute',
      width: '300px',
      transform: 'rotate(5deg)',
      zIndex: -1,
    },
  },
  bottomCard: {
    position: 'absolute',
    width: '300px',
    transform: 'rotate(-5deg)',
    backgroundColor: colors.palette_color_3,
    top: '100px',
    bottom: '-100px',
    right: '-25px',
    boxShadow: '0px -15px 20px rgba(0,0,0,.2)',
    borderRadius: '12px',
    '@media (min-width: 768px)': {
      bottom: '-10%',
      top: 'inherit',
      left: '65%',
      height: '35%',
      position: 'fixed',
      zIndex: -1,
      transform: 'rotate(-10deg)',
    },
  },
  whiteCard: {
    position: 'fixed',
    transform: 'rotate(8deg)',
    backgroundColor: 'white',
    height: '160px',
    right: '-25px',
    left: '-5px',
    bottom: '-30px',
    borderRadius: '12px',
    zIndex: 2,
    '@media (min-width: 768px)': {
      maxWidth: '50%',
      bottom: '-15%',
      left: 'inherit',
      right: '30%',
      width: '400px',
      overflow: 'visible',
      zIndex: -1,
      '& > div': {
        display: 'none',
      },
    },
  },
  langWrp: {
    position: 'absolute',
    top: '-20px',
    transform: 'rotate(-8deg) translate(-50%)',
    left: '45%',
  },
  bottomWrp: {
    position: 'relative',
    overflow: 'hidden',
    height: '350px',
    marginTop: '-200px',
  },
  thumbPic: {
    position: 'absolute',
    width: '200px',
    height: '200px',
    right: '-35px',
    transform: 'rotate(12deg)',
    zIndex: 1,
    '@media (min-width: 768px)': {
      bottom: '10%',
      top: 'inherit',
      right: '-40px',
      position: 'fixed',
    },
  },
  startButton: {
    position: 'absolute',
    transform: 'rotate(-8deg)',
    bottom: '60px',
    left: '25px',
    right: '45px',
    '@media (min-width: 768px)': {
      maxWidth: '50%',
      left: '175px',
    },
  },
  white: {
    zIndex: -1,
    transform: 'rotate(-8deg)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    height: '100px', '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  subtitle: {
    color: 'black',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.01em',
    lineHeight: '22px',
    '@media (min-width: 768px)': {
      transform: 'rotate(-5deg)',
    },
  },
  body: {
    marginTop: '2rem',
    color: 'black',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.01em',
    lineHeight: '22px',
    '@media (min-width: 768px)': {
      transform: 'rotate(-5deg)',
      margin: '16px 16px 0 12px',
    },
  },
  bottomDrawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '12px 12px 0 0',
    },
  },
});
const HomeContainer = () => {
  const classes = useStyles();
  const [{ data }] = GetLocaleHook();
  const [animate, setAnimate] = useState<boolean>(false);
  const [drawer, toggleDrawer] = useState<boolean>(false);
  const { t } = useTranslation('home');
  useEffect(
    () => {
      setTimeout(
        () => {
          setAnimate(true);
        },
        200,
      );
    },
    [],
  );
  return (
    <Grid container justify={'center'} className={classes.wrapper}>
      <Grid item xs={12} md={7}>
        <div className={classes.background}/>
        <div className={classes.topCard}/>
          <AppBarComponent/>
        {process.env.REACT_APP_QUIZ_TYPE === 'digital' &&
        <img
          className={[classes.topPic2, animate ? classes.animateTopPic2 : ''].join(' ')}
          src={pictures.landing_2}
          alt={t('title')}/>}
        <img
          className={[classes.topPic, animate ? classes.animateTopPic : ''].join(' ')}
          src={pictures.landing}
          alt={t('title')}/>
        <Typography className={classes.title}>
          {t('title')}
        </Typography>
        <div className={classes.alternativeCTAS}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LanguagesMenuComponent/>
            </Grid>
            <Grid item xs={6}>
              <StartQuizButtonComponent handleClick={() => toggleDrawer(true)}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.textCard}>
          <Typography
            className={classes.subtitle}>
            {t('subtitle')}
          </Typography>
          <Typography
            className={classes.body}>
            {t('body_1')}
            <br/>
            {t('body_2')}
          </Typography>
        </div>
        <div className={classes.bottomWrp}>
          <img
            className={classes.thumbPic}
            src={ThumbPic}
            alt={t('body_1')}/>
          <div className={classes.bottomCard}/>
          <div className={classes.whiteCard}>
            <div className={classes.langWrp}>
              <LanguagesMenuComponent/>
            </div>
            <div className={classes.startButton}>
              <Grid container justify={'center'}>
                <Grid item xs={12} sm={7}>
                  <StartQuizButtonComponent handleClick={() => toggleDrawer(true)}/>
                </Grid>
              </Grid>
            </div>
            <div className={classes.white}/>
          </div>
        </div>
      </Grid>
      <Hidden smDown>
        <Dialog
          onClose={() => toggleDrawer(false)}
          aria-labelledby="start-quiz"
          open={drawer}>
          <Box my={2}>
            <UserDataComponent country={data?.country || ''}/>
          </Box>
        </Dialog>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          anchor={'bottom'}
          open={drawer}
          className={classes.bottomDrawer}
          onClose={() => toggleDrawer(false)}>
          <Box my={2}>
            <UserDataComponent country={data?.country || ''}/>
          </Box>
        </Drawer>
      </Hidden>
    </Grid>
  );
};

export default HomeContainer;

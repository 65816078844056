import React, { FC, useMemo } from 'react';
import { QuizStepType } from '../../../utils/quiz-steps.utils';
import WrongPic from '../../../assets/pictures/icons/ico no.svg';
import CorrectPic from '../../../assets/pictures/icons/ico yes.svg';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  flipped: {
    transform: 'rotate(180deg)',
  },
  icon: {
    width: '38px',
    height: '38px',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '.9em',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.1em',
    textAlign: 'left',
    width: '80%',
    display: 'block',
  },
  decorator: {
    fontFamily: 'Roboto',
    fontSize: '.7em',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    position: 'absolute',
    transform: 'rotate(90deg)',
    color: 'white',
    right: '-12px',
    top: 0
  },
});

type Props = {
  stepType: QuizStepType;
  error: boolean;
  flipped?: boolean;
}
const ResultLabelComponent: FC<Props> = ({ flipped = false, error, stepType }) => {
  const { t } = useTranslation('ui');
  const classes = useStyles();
  const Picture = useMemo(
    () => {
      return error ? WrongPic : CorrectPic;
    },
    [error],
  );
  const label = useMemo(
    () => {
      if (stepType === 'YN') {
        return error ? t('wrong_yn') : t('correct_yn');
      }
      return error ? t('wrong_tf') : t('correct_tf');
    },
    [error, stepType, t],
  );
  return (
    <Grid container justify={'space-between'} className={flipped ? classes.flipped : ''}>
      <Grid item xs>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          style={{ position: 'relative' }}
          wrap={'nowrap'}>
          <img
            className={classes.icon}
            src={Picture}
            alt={label}/>
          <Grid item>
            <Typography
              className={classes.label}
              variant={'overline'}>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box position={'relative'}>
          <span className={classes.decorator}>{t('did_you_know')}</span>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResultLabelComponent;

export const share = (title: string, text: string)=> {
  window.navigator.share({
    title,
    text,
    url: process.env.PUBLIC_URL,
  })
}

export const trackOutboundLink = function(url?:string) {
  console.error(url);
  if (url) {
    console.warn(url);
    (window as any).gtag('event', 'click', {
      'event_category': 'outbound',
      'event_label': url,
      'transport_type': 'beacon'
    });
  }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import QuizProgressComponent from '../../components/quiz-progress/quiz-progress.component';
import { QuizStep, QuizSteps } from '../../../utils/quiz-steps.utils';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import QuizButtonsComponent from '../../components/quiz-buttons/quiz-buttons.component';
import FlipCardComponent from '../../components/flip-card/flip-card.component';
import { requireAll } from '../../../utils/i18n';
import DotCircle from './dot-pattern.code';
import './dot-pattern.css';
import ResultLabelComponent from '../../components/result-label/result-label.component';
import HandPointingPic from '../../../assets/pictures/hand_pointing.png';
import GreenTopLeftPic from '../../../assets/pictures/green/quiz_bg_top_left.png';
import GreenBottomRightPic from '../../../assets/pictures/green/quiz_bg_bottom_right.png';
import DigitalTopLeftPic from '../../../assets/pictures/digital/quiz_bg_top_left.png';
import DigitalBottomRightPic from '../../../assets/pictures/digital/quiz_bg_bottom_right.png';
import { Hidden } from '@material-ui/core';
import ShareButtonComponent from '../../components/share-button/share-button.component';
import '../../../themes/styles/wcag.css';

type StyleProps = {
  background: string;
  front: string;
  picture: string;
  textColor: string;
  showBack: boolean;
  error: boolean;
}
const useStyles = makeStyles({
  mrtd_preloaded_hidden: {
    visibility:'hidden',
    zIndex:-100,
    position:'fixed',
    pointerEvents:'none',
    opacity:'0.00000001',
  },
  '@keyframes fadein': {
    from: { opacity: 0 },
    to :  { opacity: 1 }
  },
  wrapper: ({ background, showBack }: StyleProps) => ({
    '& > div': {
      minHeight: 'var(--vh100)',
      padding: '8px 16px 24px',
      overflowX: 'hidden',
      overflowY: 'hidden',
      '@media (max-height: 650px)': {
        padding: '0 16px 12px',
      },
    },
    backgroundColor: showBack ? 'white' : background,
    transition: 'background-color .25s ease-in',
  }),
  bgPic_topR: {
    position: 'fixed',
    right: '-12vw',
    top: '-8vw',
    width: '40vw',
    transform: 'rotate(-125deg) scaleX(-1)',
    opacity: '.2',
    pointerEvents: 'none',
    mixBlendMode: 'multiply',
  },
  bgPic_topL: {
    position: 'fixed',
    left: '-4vw',
    top: '6vw',
    width: '15vw',
    opacity: '.2',
    pointerEvents: 'none',
  },
  bgPic_bottomR: {
    opacity: '.2',
    position: 'fixed',
    right: 0,
    bottom: '20px',
    transform: 'rotate(0deg)',
    width: '20vw',
    pointerEvents: 'none',
  },
  bgPic_bottomL: {
    opacity: '.2',
    position: 'fixed',
    left: '-13vw',
    bottom: '-15vw',
    width: '40vw',
    transform: 'rotate(70deg)',
    pointerEvents: 'none',
    mixBlendMode: 'multiply',
  },
  progressWrp: {
    alignSelf: 'center',
    width: '100%',
    pointerEvents: 'none',
    maxWidth: '430px',
  },
  progress: ({ showBack }) => ({
    opacity: showBack ? 0 : 1,
    pointerEvents: 'none',
  }),
  cardContainer: {
    flexShrink: 0,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0 80px',
    '@media (min-width: 960px)': {
      padding: '0!important',
      flex: 1,
    },
    '@media (max-height: 610px)': {
      transform: 'scale3d(0.9,0.9,0.9)',
      padding: '0 0 72px',
      margin: '-25px 0',
    },
    '@media (max-height: 550px)': {
      transform: 'scale3d(0.8,0.8,0.8)',
      padding: '0 0 82px',
      margin: '-50px 0',
    },
  },
  cardWrp: {
    maxWidth: '305px',
    width: '100%',
    display: 'flex',
  },
  front: ({ front }) => ({
    backgroundColor: front,
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    position: 'relative',
  }),
  question: ({ textColor }) => ({
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '25px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: textColor,
    '@media (max-width: 320px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  }),
  mediaWrp: {
    height: '305px',
    overflowX: 'visible',
    position: 'relative',
  },
  media: {
    position: 'absolute',
    top: '-20px',
    left: '-20px',
    right: '-20px',
    height: 'auto',
    width: 'calc(100% + 40px)',
  },
  cardContent: {
    height: '100%',
    maxHeight: '40%',
  },
  answer: ({ error }) => ({
    backgroundColor: error ? '#E06E7B' : '#73E0B9',
    height: '100%',
    width: '100%',
    borderRadius: '12px',
    padding: '0!important',
    position: 'absolute',
    '& > div': {
      height: '100%',
      padding: '24px',
    },
    '& p': {
      fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
      fontSize: '1.714em',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '35px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
      maxHeight: '350px',
      overflowY: 'auto',
      '@media (max-width: 320px)': {
        fontSize: '1.5em',
        lineHeight: '28px',
      },
    },
  }),
  headerTxt: {
    fontFamily: 'Work Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: 'white',
    maxWidth: '50%',
  },
  quizBtnWrp: ({ showBack }) => ({
    transform: 'translateY(50%)',
    '& > div': {
      maxWidth: showBack ? '305px' : '100%',
      width: '100%',
    },
  }),
  fadeIn: {
    animation: '$fadein 1s',
  },
  moveOut: {
    '-webkit-transition': 'all 1s ease-in-out',
    transition: 'all 1s ease-in-out',
    '-webkit-transform': 'translateY(-120vh) rotate(-15deg)',
    transform: 'translateY(-120vh) rotate(-15deg)',
  },
});
const shadowRotations = [1.4, -2.9];
const Pictures = requireAll((require as any).context(`../../../assets/pictures/${process.env.REACT_APP_QUIZ_TYPE}/`, true, /\.png$/));


const TheQuizContainer = () => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<QuizStep[]>(QuizSteps);
  const [moveOut, setMoveOut] = useState<boolean>(false);
  const [showBack, setShowBack] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const activeStep: number = useMemo(
    () => {
      if (steps.some(({ completed }) => !completed)) {
        return steps.map(({ completed }) => completed).indexOf(false);
      } else {
        return steps.length;
      }
    },
    [steps],
  );
  const picture = useMemo(
    () => Pictures[activeStep + 1] ? Pictures[activeStep + 1].default : '',
    [activeStep],
  );
  const currentStep: QuizStep | undefined = steps[activeStep];
  const classes = useStyles({
    background: currentStep?.background,
    front: currentStep?.front,
    textColor: currentStep?.textColor,
    picture,
    showBack,
    error,
  });


  //image preload
  let mrtdItems: any[] = [];
  for (let i = 1; i < 9; i++) {
    mrtdItems.push(<img className={classes.mrtd_preloaded_hidden} src={Pictures[i].default} />)
  }

  document.body.style.backgroundColor = showBack ? 'white' : currentStep?.background || 'white';

  const completed = useMemo(
    () => steps.filter(({ completed }) => !!completed).length,
    [steps],
  );
  const handleAnswer = useCallback(
    (answer: 0 | 1) => {
      setError(steps[activeStep].answer !== answer);
      setShowBack(true);
    },
    [activeStep, steps],
  );
  const handleNext = useCallback(
    () => {
      setMoveOut(true);
      setTimeout(
        ()=> {
          setShowBack(false)
          let s = steps;
          s[activeStep] = {
            ...s[activeStep],
            completed: true,
          };
          setSteps([
            ...s,
          ]);
        },
        1000
      )
      setTimeout(
        () => {
          setMoveOut(false);
        },
        1100,
      );
    },
    [activeStep, steps],
  );
  useEffect(
    () => {
      try {
        if (showBack) {
          DotCircle();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [showBack],
  );
  return (

    <Box
      className={classes.wrapper}>
      {mrtdItems}
      {!showBack &&
      <Hidden smDown>
        <img className={classes.bgPic_topR} src={HandPointingPic} alt="Background"/>
        <img className={classes.bgPic_topL}
             src={process.env.REACT_APP_QUIZ_TYPE === 'green' ? GreenTopLeftPic : DigitalTopLeftPic} alt="Background"/>
        <img className={classes.bgPic_bottomR}
             src={process.env.REACT_APP_QUIZ_TYPE === 'green' ? GreenBottomRightPic : DigitalBottomRightPic}
             alt="Background"/>
        <img className={classes.bgPic_bottomL} src={HandPointingPic} alt="Background"/>
        <Box
          position={'fixed'}
          right={'16px'}
          top={'8px'}
        >
          <ShareButtonComponent color={'white'}/>
        </Box>
        <Box
          position={'fixed'}
          left={'16px'}
          top={'8px'}
        >
          <Typography className={classes.headerTxt}>
            {t('home:title')}
          </Typography>
        </Box>
      </Hidden>}
      {showBack &&
      <div id="dot-pattern-2-wrapper">
        <canvas id="dot-pattern-2"/>
      </div>}
      <Grid
        container
        wrap={'nowrap'}
        direction={'column'}
        justify={'space-between'}>
        <Grid item className={classes.progressWrp}>
          <Box mx={1} mt={2} className={classes.progress}>
            <QuizProgressComponent completed={completed + 1} total={steps.length}/>
          </Box>
        </Grid>
        <Grid className={classes.cardContainer} item>
          <Box
            className={[classes.cardWrp, moveOut ? classes.moveOut : classes.fadeIn].join(' ')}
            display={'flex'}
            alignItems={'center'}>
            <FlipCardComponent
              shadowRotation={shadowRotations[Math.floor(Math.random() * shadowRotations.length)]}
              shadowColor={steps[activeStep].shadow}
              flip={showBack}
              front={
                <div className={classes.front}>
                  <div className={classes.mediaWrp}>
                    <img
                      className={classes.media}
                      src={picture}
                      alt={t(`quiz:question_${currentStep?.id}`)}
                    />
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      className={classes.question}
                      component="p">
                      {t(`quiz:question_${currentStep?.id}`)}
                    </Typography>
                  </CardContent>
                </div>
              }
              back={
                <CardContent className={classes.answer}>
                  <Grid
                    container
                    direction={'column'}
                    justify={'space-between'}
                    wrap={'nowrap'}
                    spacing={1}>
                    <Grid item>
                      <ResultLabelComponent
                        stepType={currentStep.type}
                        error={error}/>
                    </Grid>
                    <Grid item>
                      <Typography component={'p'} style={{ wordBreak: 'break-word' }}>
                        {t(`quiz:answer_${currentStep?.id}`)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ResultLabelComponent
                        flipped
                        stepType={currentStep.type}
                        error={error}/>
                    </Grid>
                  </Grid>
                </CardContent>
              }
            />
          </Box>
        </Grid>
        <Hidden mdUp>
          <Grid item style={{ position: 'relative' }}>
            <Box
              position={'absolute'}
              left={0}
              right={0}
              bottom={0}>
              <QuizButtonsComponent
                showBack={showBack}
                stepType={currentStep?.type}
                activeStep={activeStep}
                answers={steps.map(({ answer }) => answer)}
                lastStep={activeStep === steps.length - 1}
                handleAnswer={handleAnswer}
                handleNext={handleNext}/>
            </Box>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Box
            className={classes.quizBtnWrp}
            position={'absolute'}
            display={'flex'}
            justifyContent={showBack ? 'center' : 'space-between'}
            left={showBack ? '35vw' : '19vw'}
            right={showBack ? '35vw' : '19vw'}
            bottom={showBack ? '10%' : '50%'}>
            <QuizButtonsComponent
              showBack={showBack}
              stepType={currentStep?.type}
              activeStep={activeStep}
              answers={steps.map(({ answer }) => answer)}
              lastStep={activeStep === steps.length - 1}
              handleAnswer={handleAnswer}
              handleNext={handleNext}/>
          </Box>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default TheQuizContainer;

import { generatePath } from 'react-router-dom';

export const getRouteFactory = <RouteKeys = any>(routes: Map<RouteKeys, string> | Record<string, string>, fallbackRoute = '/') => {
  const routeMap = !(routes instanceof Map) ? new Map<RouteKeys, string>(Object.entries(routes) as any) : routes;

  return function getRoute(key: RouteKeys, replaces?: Record<string, any>) {
    if (!routeMap.has(key)) {
      return fallbackRoute; // Fallback to index
    }
    const path = routeMap.get(key)!;
    try {
      return generatePath(path, replaces);
    } catch (e) {
      console.error(e);
      return fallbackRoute;
    }
  };
};

import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from './routes';
import { routes } from './routes/routes';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import LoadingContainer from './views/containers/loading/loading.container';

function App() {
  return (
    <Fragment>
      <CssBaseline/>
      <Router basename={'/' + process.env.REACT_APP_QUIZ_TYPE}>
        <Suspense fallback={<LoadingContainer/>}>
          <Switch>
            {renderRoutes(routes)}
            <Redirect to={'/'}/>
          </Switch>
        </Suspense>
      </Router>
    </Fragment>
  );
}

export default App;

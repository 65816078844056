import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { dataFetchReducer } from '../utils/axsios.states';
import { useTranslation } from 'react-i18next';

export type UserAnswers = {
  answers: number[]
}
const url = `${process.env.REACT_APP_ENDPOINT}/api/quiz`;
const SaveUserAnswersHook = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<UserAnswers>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: undefined,
  });

  useEffect(() => {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await axios(url, {
            method:"POST",
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              id: sessionStorage.getItem('SESSION_ID'),
              quiz: process.env.REACT_APP_QUIZ_TYPE,
              language: i18n.language,
              answers: data?.answers,
            }
          });

          try {
            (window as any).gtag('event', 'quiz_complete', {
              'quiz': process.env.REACT_APP_QUIZ_TYPE === "digital" ? "Digital" : "Green"
            });

            (window as any).fathom.trackGoal(process.env.REACT_APP_QUIZ_TYPE === "digital" ? "RUKM9PXZ" : "EEWNCW75", 0);
          }catch(e){
            console.log(e);
          }

          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      };
      if (data) {
        fetchData();
      }
      return () => {
        didCancel = true;
      };
    },
    [data, i18n],
  );
  return [state, setData];
};

export default SaveUserAnswersHook;

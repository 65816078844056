import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const LoadingContainer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress size={24} color={'inherit'}/>
    </Box>
  );
};

export default LoadingContainer;

import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { dataFetchReducer } from '../utils/axsios.states';
import { useTranslation } from 'react-i18next';

export type EmailType = {
  consent: boolean;
  email: string;
}
const url = `${process.env.REACT_APP_ENDPOINT}/api/raffle`;
const SaveUserEmailHook = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<EmailType>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: undefined,
  });

  useEffect(() => {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await axios(url, {
            method:"POST",
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              quiz: process.env.REACT_APP_QUIZ_TYPE,
              email: data?.email,
              language: i18n.language,
              consent: data?.consent,
            },
          });
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      };
      if (data) {
        fetchData();
      }
      return () => {
        didCancel = true;
      };
    },
    [data, i18n],
  );
  return [state, setData];
};

export default SaveUserEmailHook;

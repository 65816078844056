type ColorsType = {
  palette_color_1: string;
  palette_color_2: string;
  palette_color_3: string;
  palette_color_4: string;
  palette_color_5: string;
}
let colors: ColorsType;
if (process.env.REACT_APP_QUIZ_TYPE === 'green') {
  // GREEN THEME
  colors = {
    palette_color_1: '#1D4F9C',
    palette_color_2: '#B1CF6D',
    palette_color_3: '#617060',
    palette_color_4: '#EFA74E',
    palette_color_5: '#E1D7C9',
  };
} else {
  //DIGITAL THEME
  colors = {
    palette_color_1: '#061853',
    palette_color_2: '#78C2E8',
    palette_color_3: '#378DA8',
    palette_color_4: '#E2DD57',
    palette_color_5: '#E7E8EF',
  };
}
export default colors;

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Facebook } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import { useTranslation } from 'react-i18next';
import { trackOutboundLink } from '../../../utils/share';

const useStyles = makeStyles({
  toolbar: {
    backgroundColor: colors.palette_color_4,
    color: 'black',
    borderBottom: '1px solid black',
  },
  facebookBtn: {
    backgroundColor: '#3B5998',
    color: 'white',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3B5998',
    },
    '&:focus': {
      backgroundColor: '#3B5998',
    },
  },
});
const ResultAppBarComponent = () => {
  const classes = useStyles();
  const {t} = useTranslation('result')
  return (
    <Box mb={8}>
      <AppBar elevation={0}>
        <Toolbar
          variant={'dense'}
          className={classes.toolbar}>
          <Grid
            container
            alignItems={'center'}
            justify={'center'}
            spacing={1}>
            <Grid item>
              <Typography variant={'body1'}>
                {t('check_out')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disableFocusRipple
                href={'https://www.facebook.com/events/119061183165620/'}
                onClick={trackOutboundLink.bind(this,'https://www.facebook.com/events/119061183165620/')}
                target={'_blank'}
                disableRipple
                disableTouchRipple
                className={classes.facebookBtn}
                variant={'contained'}
                size={'small'}
                endIcon={<Facebook fontSize={'small'}/>}>
                {t('ui:btn_event_page')}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ResultAppBarComponent;

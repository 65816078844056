import React, { useCallback, useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EuropePic from '../../../assets/pictures/icons/eucom.png';
import HandWinningPic from '../../../assets/pictures/hand_winning.png';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import ResultCardComponent from '../../components/result-card/result-card.component';
import LargeButtonComponent from '../../components/large-button/large-button.component';
import SaveUserEmailHook from '../../../hooks/save-user-email.hook';
import ResultAppBarComponent from '../../components/result-app-bar/result-app-bar.component';
import HandPointingPic from '../../../assets/pictures/hand_pointing.png';
import ThumbUpPic from '../../../assets/pictures/hand_thumb_up.png';
import ShareButtonComponent from '../../components/share-button/share-button.component';
import Link from '@material-ui/core/Link';
import '../../../themes/styles/animation.css';
import '../../../themes/styles/wcag.css';
import Button from '@material-ui/core/Button';
import { trackOutboundLink } from '../../../utils/share';

const useStyles = makeStyles({
  wrp: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: 'hidden',
  },
  resultWrp: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  shareWrp: {
    textAlign:'center',
    '& .shareWrapper': {
      textAlign: 'center !important',
    },
  },
  shareLbl: {
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    color: '#1D4F9C',
  },
  greyCard: {
    position: 'absolute',
    backgroundColor: process.env.REACT_APP_QUIZ_TYPE === 'grenn' ? colors.palette_color_5 : colors.palette_color_4,
    width: '280px',
    height: '440px',
    borderRadius: '12px',
    left: '-18px',
    transform: 'rotate(10deg)',
    zIndex: -1,
    '@media (min-width: 768px)': {
      left: '15%',
      top: '-10%',
      width: '50%',
    },
  },
  primaryCard: {
    position: 'absolute',
    width: '120%',
    height: '437px',
    top: '35px',
    left: '-10%',
    right: '0',
    backgroundColor: colors.palette_color_3,
    borderRadius: '12px',
    transform: 'rotate(-19deg)',
    zIndex: -1,
    '@media (min-width: 768px)': {
      top: 'inherit',
      maxWidth: 'unset',
      width: '110%',
      height: '450px',
      transform: 'rotate(-10deg)',
    },
  },
  primaryBackground: {
    position: 'absolute',
    width: '100%',
    top: '155px',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.palette_color_3,
    zIndex: -1,
    '@media (min-width: 768px)': {
      top: '200px',
      bottom: '150px',
    },
  },
  eventSub: {
    color: 'white',
    padding: '120px 24px 6px',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '32px',
    fontStyle: 'normal',
    maxWidth: '100vw',
    wordBreak: 'break-word',
    fontWeight: 400,
    lineHeight: '35px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    '@media (min-width: 768px)': {
      marginTop: '75px',
      marginBottom: '35px',
      width: '70%',
      margin: '0 auto',
      display: 'block',
    },
  },
  eventTitle: {
    padding: '0 24px',
    color: colors.palette_color_2,
    wordBreak: 'break-word',
    maxWidth: '100vw',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '46px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '51px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    '@media (min-width: 768px)': {
      width: '70%',
      margin: '0 auto',
      display: 'block',
    },
    '@media (max-width: 320px)': {
      fontSize: '38px',
      lineHeight: '42px',
    },
  },
  eventBtn: {
    animation: 'mrtd_pulse 1.5s',
    borderRadius: '5px',
    animationIterationCount: 'infinite',
    margin: '50px 24px 80px',
    wordBreak: 'break-word',
    '@media (min-width: 768px)': {
      maxWidth: '35%',
      display: 'block',
      marginLeft: '17%',
    },
    '& > div > button': {
      backgroundColor: `${colors.palette_color_4}!important`,
      color: 'black',
    },
    '& > div > div': {
      backgroundColor: `${colors.palette_color_2}!important`,
    },
  },
  winnerPic: {
    position: 'absolute',
    width: '292px',
    height: '292px',
    right: '-10px',
    top: '-80px',
    '@media (min-width: 768px)': {
      top: '-310px',
    },
  },
  conversionTitle: {
    margin: '190px 24px 12px',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '1.8em',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '29px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    '@media (min-width: 768px)': {
      margin: '10px 24px 0',
    },
  },
  emailWrp: {
    width: '-webkit-fill-available',
    margin: '0 24px',
    borderColor: 'rgba(225, 215, 201, .2)',
    '& > p': {
      fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.01em',
      textAlign: 'left',
      color: colors.palette_color_1,
      textTransform: 'capitalize',
    },
    '& input': {
      backgroundColor: 'rgba(225, 215, 201, .2)',
    },
  },
  consentWrp: {
    margin: '0 24px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  consentLbl: {
    marginTop: '12px',
    display: 'block',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '0.01em',
    textAlign: 'left',
  },
  enterToWin: {
    margin: '0 24px',
    '& > div > button': {
      fontSize: '1.6em',
      border: '4px solid #D3D3DA',
      backgroundColor: `white!important`,
      color: 'black',
    },
    '& > div > div': {
      border: '4px solid #D3D3DA',
      backgroundColor: `white!important`,
    },
  },
  euLbl: {
    margin: '0 24px',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    color: 'black',
  },
  euLink: {
    textDecoration: 'none',
    color: 'black',
  },
  rightBackgroundPic: {
    position: 'absolute',
    right: '-12vw',
    top: '8vw',
    width: '45vw',
    transform: 'rotate(5deg)',
    opacity: '.2',
    pointerEvents: 'none',
    zIndex: -1,
  },
  leftBackgroundPic: {
    opacity: '.2',
    position: 'absolute',
    left: '-16vw',
    bottom: 0,
    width: '45vw',
    transform: 'rotate(60deg)',
    pointerEvents: 'none',
    zIndex: -1,
  },
  hashTag: {
    fontWeight:'bold',
    fontSize:'1.3em',
    display: 'block',
    color: colors.palette_color_1,
  },
  readMore: {
    textTransform: 'capitalize',
  }
});


let submitted=false;



const TheResultContainer = () => {
  const { t } = useTranslation('result');
  const classes = useStyles();
  const [{ loading }, save] = SaveUserEmailHook();
  const [email, setEmail] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const submit = useCallback(
    async () => {
      try {
        if(consent && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
          await save({
            consent,
            email,
          });
          submitted=true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    [consent, email, save],
  );

  const ec_splitted = t('email_consent').split('.');

  const email_consent_1 = ec_splitted[0] + '. ';
  const email_consent_2 = ec_splitted[1].trimLeft() + '.';

  return (
    <div className={classes.wrp}>
      <ResultAppBarComponent/>
      <Grid
        container
        justify={'center'}
        alignItems={'center'}
        spacing={10}>
        <Hidden smDown>
          <img className={classes.leftBackgroundPic} src={HandPointingPic} alt="Background"/>
          <img className={classes.rightBackgroundPic} src={ThumbUpPic} alt="Background"/>
        </Hidden>
        <Grid item xs={12} md={'auto'}>
          <Box className={classes.resultWrp}>
            <ResultCardComponent/>
          </Box>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <Box my={1} className={classes.shareWrp}
          >
            <Typography
              className={classes.shareLbl}
              align={'center'}>
              {t('challange_friends')}
            </Typography>
            <Box
              my={1}
              display={'flex'}
              justifyContent={'center'}
            >
              <ShareButtonComponent/>
            </Box>
            <div
              className={classes.hashTag}
            >
              #YourSkillsAndJobs
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} className={'requiresArrow'}>
          <Box position={'relative'}>
            <div className={classes.greyCard}/>
            <div className={classes.primaryCard}/>
            <div className={classes.primaryBackground}/>
            <Grid
              container
              direction={'column'}>
              <Grid item>
                <Typography
                  className={classes.eventSub}
                  variant={'h4'}
                  paragraph>
                  {t('subtitle_1')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.eventTitle}
                  variant={'h3'}>
                  <strong>
                    {t('subtitle_2')}
                  </strong>
                </Typography>
              </Grid>
              <Grid item>
                <Box className={classes.eventBtn}>
                  <LargeButtonComponent
                    href={'https://www.facebook.com/events/119061183165620/'}
                    target={'_blank'}
                  >
                    {t('ui:btn_event_page')}
                  </LargeButtonComponent>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box position={'relative'}>
            <img
              className={classes.winnerPic}
              src={HandWinningPic}
              alt={t('result_subtitle_2')}/>
          </Box>
          <Grid container justify={'center'} alignItems={'flex-start'} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <Typography
                className={classes.conversionTitle}
                variant={'h4'}
                paragraph>
                {t('subtitle_3')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Grid container direction={'column'} spacing={2}>
                <Grid item>
                  <FormControl
                    fullWidth
                    required
                    className={classes.emailWrp}
                    aria-required={'true'}>
                    <Typography
                      variant={'body1'}
                      gutterBottom>
                      {t('email')}
                    </Typography>
                    <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        autoComplete: 'email',
                      }}
                      variant={'outlined'}
                      className={submitted?'mrtd_tick':'mrtd_untick'}
                      type={'email'}/>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={classes.consentWrp}
                    control={
                      <Checkbox
                        color={'default'}
                        checked={consent}
                        onChange={() => setConsent(!consent)}
                        name="consent"/>
                    }
                    label={
                      <span className={classes.consentLbl}>
                        {email_consent_1}

                        <a
                          href={'https://ec.europa.eu/dpo-register/detail/DPR-EC-06866'}
                          onClick={trackOutboundLink.bind(this,'https://ec.europa.eu/dpo-register/detail/DPR-EC-06866')}
                          target={'_blank'}
                          className={classes.readMore}
                        >
                        {email_consent_2}
                      </a>
                      </span>

                      }
                  />
                </Grid>
                <Grid item>
                  <Box className={classes.enterToWin}>
                    <LargeButtonComponent
                      onClick={submit}
                      loading={loading}
                      disabled={!consent||submitted}
                      id={submitted||!consent?'mrtd_btn_submitted':'mrtd_btn_unsubmitted'}
                    >
                      {t('ui:btn_submit_email')}
                    </LargeButtonComponent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            my={6}>
            <a
              rel={'noopener'}
              className={classes.euLink}
              href={'https://ec.europa.eu/social/home.jsp'}
              onClick={trackOutboundLink.bind(this,'https://ec.europa.eu/social/home.jsp')}
              target={'_blank'}
            >
              <img
                height={50}
                src={EuropePic}
                alt={t('europe_commission')}/>
            </a>
            <Box mt={3}
            textAlign={'center'}>
                <Typography
                  rel={'noopener'}
                  href={'https://ec.europa.eu/social/home.jsp'}
                  target={'_blank'}
                  component={Link}
                  className={classes.euLbl}
                  align={'center'}
                  color={'textSecondary'}
                  variant={'body1'}
                  gutterBottom>
                  {t('final_footer')}
                </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default TheResultContainer;

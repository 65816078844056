import colors from '../themes/colors';

const quiz = require(`../assets/quiz/${process.env.REACT_APP_QUIZ_TYPE}.json`);

export type QuizStepType = 'TF' | 'YN';
type CardPalette = {
  background: string;
  front: string;
  textColor: string;
  shadow: string;
}
export type QuizStep = {
  order: number;
  completed: boolean;
  type: QuizStepType;
  id: number;
  answer: 0 | 1;
} & CardPalette;

const GreenPalette: CardPalette[] = [
  {
    background: colors.palette_color_2,
    front: colors.palette_color_4,
    textColor: 'black',
    shadow: colors.palette_color_3,
  },
  {
    background: colors.palette_color_3,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_4,
    front: colors.palette_color_1,
    textColor: 'white',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_3,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_4,
    front: colors.palette_color_1,
    textColor: 'white',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_3,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_2,
    front: colors.palette_color_4,
    textColor: 'black',
    shadow: colors.palette_color_3,
  },
  {
    background: colors.palette_color_3,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
];


const DigitalPalette: CardPalette[] = [
  {
    background: colors.palette_color_2,
    front: colors.palette_color_4,
    textColor: 'black',
    shadow: colors.palette_color_1,
  },
  {
    background: colors.palette_color_4,
    front: colors.palette_color_1,
    textColor: 'white',
    shadow: colors.palette_color_2,
  },
  {
    background: colors.palette_color_2,
    front: colors.palette_color_4,
    textColor: 'black',
    shadow: colors.palette_color_1,
  },
  {
    background: colors.palette_color_1,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
  {
    background: colors.palette_color_4,
    front: colors.palette_color_1,
    textColor: 'white',
    shadow: colors.palette_color_2,
  },
  {
    background: colors.palette_color_2,
    front: colors.palette_color_4,
    textColor: 'black',
    shadow: colors.palette_color_1,
  },
  {
    background: colors.palette_color_4,
    front: colors.palette_color_1,
    textColor: 'white',
    shadow: colors.palette_color_3,
  },
  {
    background: colors.palette_color_1,
    front: colors.palette_color_2,
    textColor: 'black',
    shadow: colors.palette_color_5,
  },
];
const getStep: (i: number) => CardPalette = (i) => {
  if (process.env.REACT_APP_QUIZ_TYPE === 'green') {
    return GreenPalette[i];
  }
  return DigitalPalette[i];
};
export const QuizSteps: QuizStep[] = quiz.questions.map(
  (q: any, i: number) => {
    return {
      ...q,
      order: q.id,
      completed: false,
      ...getStep(i),
    };
  },
);

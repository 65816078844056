import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import { Box, ButtonClassKey, ExtendButtonBaseTypeMap, PropTypes } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { trackOutboundLink } from '../../../utils/share';

const useStyles = makeStyles({
  btnContainer: {
    '&:active button': {
      top: '3px',
    },
    '&:hover button': {
      background: colors.palette_color_1,
    }
  },
  btnContainerDisabled: {
    opacity: '.75',
    transition: 'opacity .15s ease-in-out',
    '&:active button': {
      top: '3px',
    },
    '&:hover button': {
      background: colors.palette_color_1,
    }
  },
  btn: {
    border: '4px solid #000',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '35px',
    borderRadius: '8px',
    zIndex: 1,
    letterSpacing: '-0.01em',
    backgroundColor: 'white!important',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  shadow: {
    right: '0',
    left: '0',
    bottom: '-8px',
    top: '10%',
    position: 'absolute',
    border: '4px solid #000',
    borderRadius: '8px',
    backgroundColor: colors.palette_color_1,
  },
});
type ButtonTypeMap<P = {},
  D extends React.ElementType = 'button'> = ExtendButtonBaseTypeMap<{
  props: P & {
    /**
     * The content of the button.
     */
    children?: React.ReactNode;
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: PropTypes.Color;
    /**
     * If `true`, the button will be disabled.
     */
    disabled?: boolean;
    /**
     * If `true`, no elevation is used.
     */
    disableElevation?: boolean;
    /**
     * If `true`, the  keyboard focus ripple will be disabled.
     */
    disableFocusRipple?: boolean;
    /**
     * Element placed after the children.
     */
    endIcon?: React.ReactNode;
    /**
     * If `true`, the button will take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * The URL to link to when the button is clicked.
     * If defined, an `a` element will be used as the root node.
     */
    href?: string;
    trackOutbound?: boolean;
    /**
     * The size of the button.
     * `small` is equivalent to the dense button styling.
     */
    size?: 'small' | 'medium' | 'large';
    /**
     * Element placed before the children.
     */
    startIcon?: React.ReactNode;
    /**
     * The variant to use.
     */
    variant?: 'text' | 'outlined' | 'contained';
    loading?: boolean
  };
  defaultComponent: D;
  classKey: ButtonClassKey;
}>;
const LargeButtonComponent: ExtendButtonBase<ButtonTypeMap> = (props: any) => {
  const classes = useStyles(props.disabled);
  return (
    <Box
      position={'relative'}
      className={props.disabled?classes.btnContainerDisabled:classes.btnContainer}
    >
      <Button
        className={classes.btn}
        variant={'contained'}
        size={'large'}
        fullWidth
        disableElevation
        disableFocusRipple
        disableTouchRipple
        disableRipple
        onClick={trackOutboundLink.bind(this, props.href)}
        {...props}
        disabled={false}
      >
        {props.loading ? <CircularProgress size={32} color={'inherit'}/> : props.children}
      </Button>
      <div className={classes.shadow}/>
    </Box>
  );
};

export default LargeButtonComponent;

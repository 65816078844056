import React, { FC, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import pictures from '../../../themes/pictures';

const useStyles = makeStyles({
  result: {
    border: '6px solid black',
    borderRadius: '12px',
    backgroundColor: colors.palette_color_2,
    position: 'relative',
    width: '100%',
    maxWidth: '345px',
    zIndex: 1,
  },
  shadow: {
    maxWidth: '345px',
    position: 'absolute',
    top: '6px',
    bottom: '-6px',
    zIndex: 0,
    width: '100%',
    backgroundColor: 'black',
    borderRadius: '12px',
  },
  resultScore: {
    position: 'absolute',
    top: '245px',
    right: '6px',
    borderRadius: '100%',
    height: '104px',
    width: '104px',
    border: '6px solid black',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      margin: '16px auto 0',
      fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '35px',
      letterSpacing: '-0.01em',
      textAlign: 'center',
      color: colors.palette_color_1,
    },
    '& > span': {
      fontFamily: 'Roboto',
      fontSize: '.7em',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.05em',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  picture: {
    width: '445px',
    height: '345px',
    margin: '-16px',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  resultLbl: {
    marginTop: '345px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.1em',
    textAlign: 'left',
    color: colors.palette_color_1,
    textTransform: 'uppercase',
  },
  resultTitle: {
    color: 'white',
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '3em',
    fontStyle: 'normal',
    fontWeight: 900,
    maxWidth: '100vw',
    wordBreak: 'break-word',
    lineHeight: '45px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    marginBottom:'10px',
  },
  resultDescription: {
    fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: colors.palette_color_1,
  },
});

type ResultType = {
  title: string;
  description: string;
  picture: string;
  score: number;
}
type Props = {}
const ResultCardComponent: FC<Props> = () => {
  const { t } = useTranslation('result');
  const { state } = useLocation();
  const classes = useStyles();
  const result: ResultType = useMemo(
    () => {
      switch (state?.result) {
        case 4:
        case 5:
        case 6:
          return {
            score: state?.result,
            title: t('profile_title_2'),
            description: t('profile_desc_2'),
            picture: pictures.result_2,
          };
        case 7:
        case 8:
          return {
            score: state?.result,
            title: t('profile_title_3'),
            description: t('profile_desc_3'),
            picture: pictures.result_3,
          };
        default:
          return {
            score: state?.result || 0,
            title: t('profile_title_1'),
            description: t('profile_desc_1'),
            picture: pictures.result_1,
          };
      }
    },
    [state, t],
  );
  return (
    <Box position={'relative'}>
      <Card className={classes.result}>
        <CardContent>
          <div className={classes.resultScore}>
            <p>{result.score}/8</p>
            <span>{t('result_score')}</span>
          </div>
          <Box>
            <img
              className={classes.picture}
              src={result.picture}
              alt={result.title}/>
            <Typography className={classes.resultLbl}>
              {t('result_you_are')}
            </Typography>
            <Typography className={classes.resultTitle}>
              {result.title}
            </Typography>
            <Typography className={classes.resultDescription}>
              {result.description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <div className={classes.shadow}/>
    </Box>
  );
};

export default ResultCardComponent;

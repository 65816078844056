import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';

const CookiesComponent = () => {
  const { t } = useTranslation('privacy');
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Accordion
      style={{ border: 'none' }}
      variant={'outlined'}
      expanded={expanded}>
      <AccordionSummary
        aria-controls="cookies-content"
        id="cookies-header"
      >
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('disclaimer')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Chip
                  onClick={() => setExpanded(true)}
                  size={'small'}
                  color={'default'}
                  label={t('read_more')}/>
              </Grid>
              {/*<Grid item>
                <Link
                  color={'textSecondary'}
                  variant={'caption'}
                  onClick={() => setExpanded(false)}>
                  {t('dismiss')}
                </Link>
              </Grid>*/}
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction={'column'}
          style={{ position: 'relative' }}>
          <Box
            position={'absolute'}
            top={'-8px'}
            right={'-16px'}
          >
            <IconButton
              onClick={() => setExpanded(false)}
              size={'small'}>
              <Close color={'action'}/>
            </IconButton>
          </Box>
          <Grid item>
            <Typography variant={'caption'} color={'textSecondary'}>
              <strong>{t('ga_statistics')}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('body_1')}
            </Typography>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('body_2')}
            </Typography>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('body_3')}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CookiesComponent;

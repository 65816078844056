import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import data from '../../../utils/data';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import { ArrowDropDown } from '@material-ui/icons';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  menuBtn: {
    color: 'white',
    backgroundColor: colors.palette_color_1,
    borderRadius: '20px',
    padding: '.5rem 1rem',
    zIndex: 9,
    '&:hover': {
      backgroundColor: colors.palette_color_1,
    },
    '& span': {
      textTransform: 'capitalize',
      fontFamily: '"Work Sans", "Open Sans", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontHeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.01em',
    },
  },
});
type Props = {
  native?: boolean
}
const LanguagesMenuComponent: FC<Props> = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key: string) => {
    try {
      if (key) {
        i18n.changeLanguage(key.toLocaleLowerCase());
        history.push(`/${key.toLocaleLowerCase()}/`);
      }
    } catch (e) {
      console.error(e);
    }
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        className={classes.menuBtn}
        aria-controls="language"
        endIcon={<ArrowDropDown fontSize={'small'}/>}
        aria-haspopup="true" onClick={handleClick}>
        {data.languages[i18n.language.toUpperCase()]} ({i18n.language.toUpperCase()})
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(data.languages).map(
          (key) =>
            <MenuItem
              value={key}
              key={key}
              onClick={() => handleClose(key)}>
              {data.languages[key]}
            </MenuItem>,
        )}
      </Menu>
    </div>
  );
};

export default LanguagesMenuComponent;


import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Share } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import { share } from '../../../utils/share';
import ShareDialogComponent from '../share-dialog/share-dialog.component';


const useStyles = makeStyles({
  logo: {
    width: '50px',
    transform: 'rotate(90deg)',
    marginTop: '1rem',
  },
  logoTxt: {
    lineHeight: 1.4,
    color: 'white',
    fontSize: '.7rem',
    display: 'block',
  },
  share: {
    textTransform: 'uppercase',
    fontSize: '.6rem',
    padding: '18px 6px',
    backgroundColor: colors.palette_color_3,
    color: 'white',
    '&:hover': {
      backgroundColor: colors.palette_color_3,
    },
    '& svg': {
      fontSize: '1.2rem',
      color: 'white',
    },
  },
  payOff: {
    textAlign: 'center',
    marginTop: '5px',
    color: colors.palette_color_3,
    fontWeight: 'bold',
    textShadow: '0px 0px 5px rgba(255, 255, 255, 1)',
  },
  shareWrapper: {
    textAlign: 'center',
    marginTop: '5px',
  }
});


const AppBarComponent = () => {
  const { t } = useTranslation('ui');
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleShare = () => {
    try {
      share(t('home:title'), t( 'home:subtitle'));
    } catch (e) {
      console.error(e);
      setOpen(true);
    }
  };
  return (
    <Container>
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}>
        <Grid item>
          <Box className={classes.logo}>
            <Typography variant={'overline'} className={classes.logoTxt}>
              {process.env.REACT_APP_QUIZ_TYPE}&nbsp;
              skills
              quiz
            </Typography>
          </Box>
        </Grid>
        <Grid item
          className={classes.shareWrapper}
        >
          <Chip
            className={classes.share}
            icon={<Share color={'inherit'}/>}
            onClick={handleShare}
            label={t('result:share')}/>
            <div
              className={classes.payOff}
            >
              #YourSkillsAndJobs
            </div>
        </Grid>
      </Grid>
      <ShareDialogComponent open={open} onClose={() => setOpen(false)}/>
    </Container>
  );
};

export default AppBarComponent;

type ActionType =
  { type: 'FETCH_INIT'; payload?: [] }
  | { type: 'FETCH_SUCCESS'; payload?: [] }
  | { type: 'FETCH_FAILURE'; payload?: [] }

export const dataFetchReducer = (state: any, action: ActionType) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload || [],
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      throw new Error();
  }
};

import React, { FC, Fragment, useState } from 'react';
import { Share } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../themes/colors';
import { useTranslation } from 'react-i18next';
import ShareDialogComponent from '../share-dialog/share-dialog.component';
import { share } from '../../../utils/share';

type StyleProps = {
  color?: string;
}
const useStyles = makeStyles({
  shareWrapper: {
    textAlign:'center',
  },
  shareBtn: ({ color }: StyleProps) => ({
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.1em',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '18px 6px',
    borderRadius: '20px',
    height: '42px',
    backgroundColor: color || colors.palette_color_4,
    color: 'black',
    '&:hover': {
      backgroundColor: color || colors.palette_color_4,
    },
    '& svg': {
      fontSize: '1.2rem',
      color: 'black',
    },
  }),
  payoff: {
    color:'white',
    textAlign:'center',
    fontWeight:'bold',
    fontSize: '1.25rem',
    marginTop:'5px',
  }
});

type Props = {
  color?: string;
}
const ShareButtonComponent: FC<Props> = ({ color }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('result');
  const classes = useStyles({ color });
  const handleShare = () => {
    try {
      share(t('home:title'), t( 'home:subtitle'));
    } catch (e) {
      console.error(e);
      setOpen(true);
    }
  };
  return (
    <Fragment>
      <div
        className={classes.shareWrapper}
      >
        <Chip
          className={classes.shareBtn}
          icon={<Share/>}
          onClick={handleShare}
          label={t('share')}/>
        <ShareDialogComponent open={open} onClose={() => setOpen(false)}/>
        <div
          className={classes.payoff}
        >
          #YourSkillsAndJobs
        </div>
      </div>
    </Fragment>
  );
};

export default ShareButtonComponent;

import React, { FC, ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

type StyleProps = {
  shadowRotation: number;
  shadowColor: string;
}

const useStyles = makeStyles({
  '@keyframes revertRotation': ({ shadowRotation }: StyleProps) => ({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      bottom: '-10px',
      transform: `rotate($-10deg)`,
    },
  }),
  cardWrp: {
    position: 'relative',
    '-webkit-perspective': 800,
    perspective: 800,
    width: '100%',
    height: '100%',
  },
  card: {
    height: '480px',
    '-webkit-transform-style': 'preserve-3d',
    transformStyle: 'preserve-3d',
    '-webkit-transition': 'all 0s ease-in-out',
    transition: 'all 0s ease-in-out',
    borderRadius: '12px',
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  side: {
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    display: 'flex',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'white',
    borderRadius: '12px',
    '-webkit-transform-style': 'preserve-3d',
    transformStyle: 'preserve-3d',
    '-webkit-transition': 'rotateY 660ms ease-in-out',
    transition: 'rotateY 660ms ease-in-out',
  },
  visible: {
    '-webkit-backface-visibility': 'inherit',
    backfaceVisibility: 'inherit',
  },
  front: {
    '-webkit-transform': 'none',
    transform: 'none',
  },
  back: ({ shadowColor }: StyleProps) => ({
    backgroundColor: shadowColor,
    '-webkit-transform': 'rotateY(180deg)',
    transform: 'rotateY(180deg)',
  }),
  flipped: {
    '-webkit-transition': 'all 660ms ease-in-out',
    transition: 'all 660ms ease-in-out',
    '-webkit-transform': 'rotateY(180deg)',
    transform: 'rotateY(180deg)',
  },
  shadow: ({ shadowRotation, shadowColor }: StyleProps) => ({
      backgroundColor: shadowColor,
      position: 'absolute',
      left: 0,
      bottom: '-10px',
      top: '0px',
      right: 0,
      borderRadius: '12px',
      zIndex: -1,
      transform: `rotate(${shadowRotation}deg) translateY(5px)`,
    }
  ),
});

type Props = {
  flip: boolean
  front: ReactElement;
  back: ReactElement;
  shadowColor: string;
  shadowRotation: number;
}
const FlipCardComponent: FC<Props> = ({ shadowRotation, shadowColor, flip, front, back }) => {
  const classes = useStyles({
    shadowRotation,
    shadowColor,
  });
  return (
    <Box className={classes.cardWrp}>
      <Paper className={[classes.card, flip ? classes.flipped : ''].join(' ')}>
        <div className={[classes.side, classes.front].join(' ')}>
          {flip ? front :
            <Fade in={!flip} timeout={500}>
              {front}
            </Fade>}
        </div>
        <div className={[classes.side, classes.back].join(' ')}>
          {flip ? back :
            <Fade in={flip} timeout={1500}>
              {back}
            </Fade>}
        </div>
      </Paper>
      {!flip && <div className={classes.shadow}/>}
    </Box>
  );
};

export default FlipCardComponent;

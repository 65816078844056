import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LargeButtonComponent from '../large-button/large-button.component';


type Props = {
  handleClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}
const StartQuizButtonComponent: FC<Props> = ({ loading = false, disabled = false, handleClick }) => {
  const { t } = useTranslation('ui');
  return (
    <LargeButtonComponent
      disabled={disabled}
      onClick={handleClick}
      loading={loading}>
      {t('btn_start_quiz')}
    </LargeButtonComponent>
  );
};

export default StartQuizButtonComponent;

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { RoutesKeys } from '../../../routes/routes';
import { getRoute } from '../../../routes';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import LargeButtonComponent from '../large-button/large-button.component';
import SaveUserAnswersHook from '../../../hooks/save-user-answers.hook';


const useStyles = makeStyles({
  button: {
    textTransform:'capitalize',
  },
  positive: {
    '& > div > button': {
      maxWidth: '45vw',
    },
    '& > div > div': {
      maxWidth: '45vw',
      backgroundColor: 'black',
    },
  },
  negative: {
    '& > div > button': {
      maxWidth: '45vw',
      backgroundColor: 'black!important',
      color: 'white!important',
    },
    '& > div > div': {
      maxWidth: '45vw',
      backgroundColor: 'white',
    },
  },
  forward: {
    '& > div > button': {
      borderColor: '#D3D3DA',
      color: 'black',
      fontWeight: 400,
      fontSize: '22px',
      textTransform: 'capitalize',
    },
    '& > div > div': {
      backgroundColor: '#D3D3DA',
      borderColor: '#D3D3DA',
    },
  },
  result: {
    '& > div > a': {
      borderColor: 'black',
      backgroundColor: '#73E0B9!important',
      color: 'black',
      fontWeight: 400,
      fontSize: '22px',
      textTransform: 'capitalize',
    },
    '& > div > div': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
});
type Props = {
  showBack: boolean;
  answers: number[];
  activeStep: number;
  lastStep: boolean;
  handleNext: () => void;
  handleAnswer: (answer: 0 | 1) => void;
  stepType: 'TF' | 'YN';
}
const QuizButtonsComponent: FC<Props> = ({ answers = [], activeStep, stepType, showBack, lastStep, handleNext, handleAnswer }) => {
    const { t, i18n } = useTranslation('ui');
    const [{loading}, save] = SaveUserAnswersHook();
    const classes = useStyles();
    const history = useHistory();
    const [userAnswers, setUserAnswer] = useState<number[]>([]);
    const [result, setResult] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const stepRef = useRef<boolean>(showBack);
    useEffect(
      () => {
        if (showBack !== stepRef.current) {
          setDisabled(true);
          stepRef.current = showBack;
          setTimeout(
            () => {
              setDisabled(false);
            },
            1000,
          );
        }
      },
      [showBack, stepRef],
    );

    const onAnswer = useCallback(
      (answer: 0 | 1) => {
        setUserAnswer([
          ...userAnswers,
          answer,
        ]);
        if (answers[activeStep] === answer) {
          setResult(result + 1);
        }
        handleAnswer(answer);
      },
      [result, activeStep, handleAnswer, userAnswers, answers],
    );
    const submit = useCallback(
      async () => {
        try {
          await save({
            answers: userAnswers,
          });
        } catch (e) {
          console.error(e);
        }
        history.push({
          pathname: getRoute(RoutesKeys.result, {
            lang: i18n.language,
          }),
          state: {
            result,
          },
        });
      },
      [userAnswers, history, i18n, result, save],
    );

    if (showBack) {
      if (lastStep) {
        return (
          <Box className={classes.result}>
            <LargeButtonComponent
              disabled={disabled}
              loading={loading}
              onClick={submit}>
              {t('btn_check_results').toLowerCase()}
            </LargeButtonComponent>
          </Box>
        );
      } else {
        return (
          <Box className={classes.forward}>
            <LargeButtonComponent
              disabled={disabled}
              onClick={handleNext}>
              {t('btn_next_question').toLowerCase()}
            </LargeButtonComponent>
          </Box>
        );
      }
    }
    return (
      <Grid
        container
        spacing={1}
        justify={'space-between'}
        className={'mrtd_buttons_container'}>
        <Grid item xs md={3} className={classes.negative}>
          <LargeButtonComponent
            disabled={disabled}
            onClick={() => onAnswer(0)}>
            {(stepType === 'YN' ? t('no') : t('false')).toLowerCase()}
          </LargeButtonComponent>
        </Grid>
        <Grid item xs md={3} className={classes.positive}>
          <LargeButtonComponent
            disabled={disabled}
            onClick={() => onAnswer(1)}>
            {(stepType === 'YN' ? t('yes') : t('true')).toLowerCase()}
          </LargeButtonComponent>
        </Grid>
      </Grid>
    );

  }
;

export default QuizButtonsComponent;

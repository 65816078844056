import React from 'react';
import { Route } from 'react-router-dom';
import { routes, RoutesKeys, RouteType } from './routes';
import { getRouteFactory } from './utils';

export const renderRoutes = (routes: RouteType[]) => routes.map(({ component, exact, path }) =>
  <Route key={path} exact={exact} path={path} component={component}/>,
);

export const routeByKey = routes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);
